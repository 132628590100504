// assets
import { IconDashboard, IconUsers, IconCoin, IconUserPlus, IconCash } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUsers, IconCoin, IconUserPlus, IconCash };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'Customers',
            title: 'Customers',
            type: 'item',
            url: '/Customers',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'Transactions',
            title: 'Transactions',
            type: 'item',
            url: '/transactions',
            icon: icons.IconCoin,
            breadcrumbs: false
        },
        {
            id: 'addCustomer',
            title: 'Add Customer',
            type: 'item',
            url: '/addcustomer',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'addTransaction', 
            title: 'Add Transaction',
            type: 'item',
            url: '/addtransaction',
            icon: icons.IconCash,
            breadcrumbs: false
        },

    ]
};

export default dashboard;
