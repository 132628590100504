import { Navigate, Outlet } from 'react-router';
import { useState, useEffect, useContext } from 'react';
import { Context } from 'App';


const ProtectedRoutes = () => {
    const { signedIN } = useContext(Context);
    const loggedIN = localStorage.getItem('loggedIN')
    return  signedIN ? <Outlet /> : <Navigate to='/login' />
}
export default ProtectedRoutes;





