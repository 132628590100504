import { lazy } from 'react';
import { Navigate } from 'react-router';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { useContext } from 'react';
import { Context } from 'App';


const Auth = Loadable(lazy(() => import('views/pages/AuthLoginPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <Auth />
        },
    ]
};

export default AuthenticationRoutes;
