import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoutes  from './ProtectedRoutes';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Customers = Loadable(lazy(() => import('views/Customers')));
const Transactions = Loadable(lazy(() => import('views/Transactions')));
const AddNewCustomer = Loadable(lazy(() => import('views/AddNewCustomer')));
const AddTransaction = Loadable(lazy(() => import('views/AddNewTransaction')));
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'customers',
            element: <Customers />
        },
        {
            path: 'transactions',
            element: <Transactions />
        },
        {
            path: 'addcustomer',
            element: <AddNewCustomer />
        },
        {
            path: 'addtransaction',
            element: <AddTransaction />
        }
    ]
};

export default MainRoutes;
