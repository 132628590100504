import * as React from "react"

const SvgComponent = (props) => (
  <svg
    data-name="Layer 1"
    width={59.34}
    height={59.34}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"cabin"}</title>
    <g transform="matrix(.08333 0 0 .08333 -.061 -11.315)">
      <path
        d="M231.941 824.581c37.553-47.246 88.89-69.58 139.87-84.067 53.32-15.153 108.33-23.287 158.992-50.32 24.192-12.562 46.398-30.366 65.584-52.585 17.914-21.285 32.774-46.219 43.94-73.726 22.788-55.764 29.876-119.425 20.128-180.793-10.313-62.39-37.007-114.924-74.329-158.536-4.548-5.317 10.658 11.846 5.79 6.981-2.282-2.284-24.34-24.885-22.037-22.578 39.667 39.784 68.391 93.943 82.184 154.957 13.476 60.19 9.989 124.302-9.883 181.684-9.642 27.874-22.986 53.533-39.473 75.9-17.491 23.33-38.29 42.449-61.356 56.397-49.142 30.179-104.147 39.52-157.205 53.417-50.027 13.103-101.058 31.794-141.167 72.965a214.991 214.991 0 0 0-14.285 16.265c-2.08 2.617 1.166 6.659 3.248 4.04z"
        fill="#63ff7e"
        style={{
          opacity: 0.15,
          strokeWidth: 1.85496,
        }}
      />
      <path
        d="M356.5 847.86a356.04 356.04 0 1 1 167.295-41.606A356.627 356.627 0 0 1 356.5 847.86Zm0-710a353.5 353.5 0 1 0 165.885 665.746A356.518 356.518 0 0 0 646.28 693.87a354.039 354.039 0 0 0 35.937-340.105A353.211 353.211 0 0 0 356.5 137.86Z"
        fill="#3f3d56"
      />
      <path
        d="M231.941 824.581c37.553-47.246 88.89-69.58 139.87-84.067 53.32-15.153 108.33-23.287 158.992-50.32 24.192-12.562 46.398-30.366 65.584-52.585 17.914-21.285 32.774-46.219 43.94-73.726 22.788-55.764 29.876-119.425 20.128-180.793-10.313-62.39-37.007-114.924-74.329-158.536-4.548-5.317 10.658 11.846 5.79 6.981-2.282-2.284-24.34-24.885-22.037-22.578 39.667 39.784 68.391 93.943 82.184 154.957 13.476 60.19 9.989 124.302-9.883 181.684-9.642 27.874-22.986 53.533-39.473 75.9-17.491 23.33-38.29 42.449-61.356 56.397-49.142 30.179-104.147 39.52-157.205 53.417-50.027 13.103-101.058 31.794-141.167 72.965a214.991 214.991 0 0 0-14.285 16.265c-2.08 2.617 1.166 6.659 3.248 4.04z"
        fill="#63ff7e"
        style={{
          opacity: 0.15,
          strokeWidth: 1.85496,
        }}
      />
      <path
        d="M356.5 847.86a356.04 356.04 0 1 1 167.295-41.606A356.627 356.627 0 0 1 356.5 847.86Zm0-710a353.5 353.5 0 1 0 165.885 665.746A356.518 356.518 0 0 0 646.28 693.87a354.039 354.039 0 0 0 35.937-340.105A353.211 353.211 0 0 0 356.5 137.86Z"
        fill="#3f3d56"
      />
      <path
        d="M235.07 564.77a5.653 5.653 0 0 0 .15.59h-.15zM477.89 564.77v.59h-.15a5.666 5.666 0 0 0 .15-.59z"
        opacity={0.5}
      />
      <path
        d="M890.19 720.98C826.18 812.4 720.07 872.18 600 872.18c-121.51 0-228.72-61.22-292.47-154.5 54.9-104.35 166.37-78.5 292.47-78.5 127.42 0 235.87-24.34 290.19 81.8z"
        fill="#6c63ff"
        transform="translate(-243.5 -26.82)"
        style={{
          fill: "#00d455",
        }}
      />
      <path
        d="M356.5 637.36c121.207 0 225.245-22.014 281.858 67.197q4.273-5.614 8.332-11.396c-54.32-106.14-162.77-81.8-290.19-81.8-126.1 0-237.57-25.85-292.47 78.5q4.071 5.956 8.382 11.732c57.364-87.628 164.101-64.232 284.088-64.232Z"
        fill="#3f3d56"
      />
      <path
        style={{
          opacity: 0.0458016,
          strokeWidth: 1.85496,
        }}
        d="M653.494 298.154c-.82.572-1.622 1.17-2.424 1.778.985 1.51 1.96 3.025 2.922 4.55a69.901 69.901 0 0 1 2.438-1.753 355.418 355.418 0 0 0-2.936-4.575zm-614.869 43.36a303.844 377.93 0 0 0-3.512.484 352.939 352.939 0 0 0-2.715 5.992 297.9 370.538 0 0 1 3.46-.367c.917-2.04 1.812-4.091 2.767-6.11z"
      />
      <path
        d="M10.622 565.786c8.41 19.622 6.08 1.286 23.657 15.96 18.45 15.403 34.044 35.81 47.742 57.421 15.137 23.883 28.107 49.725 42.03 74.687 12.858 23.053 26.636 45.464 43.208 64.619 15.678 18.122 33.836 33.358 54.315 41.622a99.108 99.108 0 0 0 7.561 2.69c2.867.895 4.09-4.613 1.221-5.508-21.03-6.565-39.833-20.783-55.997-38.505-16.868-18.491-30.89-40.435-43.783-63.241-14.036-24.832-26.952-50.65-41.794-74.771-13.732-22.32-29.175-43.304-47.517-59.888-17.022-15.39-10.692-19.058-31.829-21.197-2.725-.276 2.097-3.258-.638-3.248-2.957.128-2.108.185.26 5.712z"
        fill="#63ff7e"
        style={{
          opacity: 0.150127,
          strokeWidth: 1.85496,
        }}
      />
      <path
        d="M231.787 823.99c16.248-27.138 32.86-55.924 39.874-89.126 3.492-15.848 4.004-32.484 1.497-48.618-2.425-14.068-7.151-27.365-13.884-39.063-13.809-24.397-34.167-42.528-55.831-54.767-26.829-15.158-55.76-22.93-83.762-33.86-25.811-10.075-52.275-23.222-71.556-47.709-18.217-23.135-28.54-57.96-18.379-89.282 9.22-28.42 31.687-47.267 54.115-57.217 27.498-12.2 56.95-14.396 84.971-24.064 23.427-8.084 47.401-21.866 61.906-47.173 12.292-21.446 16.238-52.373 6.412-76.343-2.396-5.811-9.402-22.959-13.299-27.336-20.076-31.117-22.024-27.963-3.247 4.04 16.57 18.676 20.548 62.559 11.213 86.213-10.961 27.774-34.628 43.596-57.408 52.582-27.113 10.696-55.805 12.979-83.05 23.042-23.285 8.602-46.063 24.023-59.766 49.798-6.654 12.343-10.303 26.813-10.51 41.672.072 15.901 3.483 31.503 9.895 45.259 12.955 28.608 36.043 47.113 59.806 59.6 49.958 26.255 109.224 30.264 150.384 78.4 17.676 20.67 29.678 48.126 28.985 78.88-.765 33.923-15.344 64.785-30.507 91.89-3.837 6.86-7.812 13.597-11.824 20.3-1.766 2.95 2.218 5.8 3.965 2.882z"
        fill="#63ff7e"
        style={{
          opacity: 0.15,
          strokeWidth: 1.85496,
        }}
      />
      <ellipse
        cx={59.55}
        cy={248.329}
        fill="#63ff7e"
        style={{
          opacity: 0.0458016,
          strokeWidth: 1.85496,
        }}
        rx={35.207}
        ry={43.792}
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: 192,
          lineHeight: 10,
          fontFamily: "'a Atmospheric'",
          InkscapeFontSpecification: "'a Atmospheric, Normal'",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          letterSpacing: 2,
          fill: "#1a1a1a",
        }}
        x={217.781}
        y={612.829}
      >
        <tspan
          x={217.781}
          y={612.829}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: 192,
            fontFamily: "'a Atmospheric'",
            InkscapeFontSpecification: "'a Atmospheric, Normal'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#1a1a1a",
          }}
        >
          {"LT"}
        </tspan>
      </text>
      <path
        d="M235.07 564.77a5.653 5.653 0 0 0 .15.59h-.15zM477.89 564.77v.59h-.15a5.666 5.666 0 0 0 .15-.59z"
        opacity={0.5}
      />
      <path
        d="M890.19 720.98C826.18 812.4 720.07 872.18 600 872.18c-121.51 0-228.72-61.22-292.47-154.5 54.9-104.35 166.37-78.5 292.47-78.5 127.42 0 235.87-24.34 290.19 81.8z"
        fill="#6c63ff"
        transform="translate(-243.5 -26.82)"
        style={{
          fill: "#00d455",
        }}
      />
      <path
        d="M356.5 637.36c121.207 0 225.245-22.014 281.858 67.197q4.273-5.614 8.332-11.396c-54.32-106.14-162.77-81.8-290.19-81.8-126.1 0-237.57-25.85-292.47 78.5q4.071 5.956 8.382 11.732c57.364-87.628 164.101-64.232 284.088-64.232Z"
        fill="#3f3d56"
      />
      <path
        style={{
          opacity: 0.0458016,
          strokeWidth: 1.85496,
        }}
        d="M653.494 298.154c-.82.572-1.622 1.17-2.424 1.778.985 1.51 1.96 3.025 2.922 4.55a69.901 69.901 0 0 1 2.438-1.753 355.418 355.418 0 0 0-2.936-4.575zm-614.869 43.36a303.844 377.93 0 0 0-3.512.484 352.939 352.939 0 0 0-2.715 5.992 297.9 370.538 0 0 1 3.46-.367c.917-2.04 1.812-4.091 2.767-6.11z"
      />
      <path
        d="M10.622 565.786c8.41 19.622 6.08 1.286 23.657 15.96 18.45 15.403 34.044 35.81 47.742 57.421 15.137 23.883 28.107 49.725 42.03 74.687 12.858 23.053 26.636 45.464 43.208 64.619 15.678 18.122 33.836 33.358 54.315 41.622a99.108 99.108 0 0 0 7.561 2.69c2.867.895 4.09-4.613 1.221-5.508-21.03-6.565-39.833-20.783-55.997-38.505-16.868-18.491-30.89-40.435-43.783-63.241-14.036-24.832-26.952-50.65-41.794-74.771-13.732-22.32-29.175-43.304-47.517-59.888-17.022-15.39-10.692-19.058-31.829-21.197-2.725-.276 2.097-3.258-.638-3.248-2.957.128-2.108.185.26 5.712z"
        fill="#63ff7e"
        style={{
          opacity: 0.150127,
          strokeWidth: 1.85496,
        }}
      />
      <path
        d="M231.787 823.99c16.248-27.138 32.86-55.924 39.874-89.126 3.492-15.848 4.004-32.484 1.497-48.618-2.425-14.068-7.151-27.365-13.884-39.063-13.809-24.397-34.167-42.528-55.831-54.767-26.829-15.158-55.76-22.93-83.762-33.86-25.811-10.075-52.275-23.222-71.556-47.709-18.217-23.135-28.54-57.96-18.379-89.282 9.22-28.42 31.687-47.267 54.115-57.217 27.498-12.2 56.95-14.396 84.971-24.064 23.427-8.084 47.401-21.866 61.906-47.173 12.292-21.446 16.238-52.373 6.412-76.343-2.396-5.811-9.402-22.959-13.299-27.336-20.076-31.117-22.024-27.963-3.247 4.04 16.57 18.676 20.548 62.559 11.213 86.213-10.961 27.774-34.628 43.596-57.408 52.582-27.113 10.696-55.805 12.979-83.05 23.042-23.285 8.602-46.063 24.023-59.766 49.798-6.654 12.343-10.303 26.813-10.51 41.672.072 15.901 3.483 31.503 9.895 45.259 12.955 28.608 36.043 47.113 59.806 59.6 49.958 26.255 109.224 30.264 150.384 78.4 17.676 20.67 29.678 48.126 28.985 78.88-.765 33.923-15.344 64.785-30.507 91.89-3.837 6.86-7.812 13.597-11.824 20.3-1.766 2.95 2.218 5.8 3.965 2.882z"
        fill="#63ff7e"
        style={{
          opacity: 0.15,
          strokeWidth: 1.85496,
        }}
      />
      <ellipse
        cx={59.55}
        cy={248.329}
        fill="#63ff7e"
        style={{
          opacity: 0.0458016,
          strokeWidth: 1.85496,
        }}
        rx={35.207}
        ry={43.792}
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: 192,
          lineHeight: 10,
          fontFamily: "'a Atmospheric'",
          InkscapeFontSpecification: "'a Atmospheric, Normal'",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          letterSpacing: 2,
          fill: "#1a1a1a",
        }}
        x={217.781}
        y={612.829}
      >
        <tspan
          x={217.781}
          y={612.829}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: 192,
            fontFamily: "'a Atmospheric'",
            InkscapeFontSpecification: "'a Atmospheric, Normal'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#1c241c",
          }}
        >
          {"LT"}
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgComponent
