import { useRoutes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AuthenticationRouteWrapper from './AuthenticationRouteWrapper';


// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {

    return useRoutes([
        {
            element: <ProtectedRoutes />,
            children: [
                {
                    ...MainRoutes
                }
            ]
        },
        {
            element: <AuthenticationRouteWrapper />,
            children: [
                {
                    ...AuthenticationRoutes
                }
            ]
        },
    ]);
}



