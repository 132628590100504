
import * as React from 'react';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useSelector } from 'react-redux'

// routing
import Routes from 'routes';

//Context
export const Context = React.createContext();

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [profile, setProfile] = useState(null);
    const [signedIN, setSignedIN] = useState(null);

    return (
        <Context.Provider value={{ profile, setProfile, setSignedIN, signedIN }}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </Context.Provider>
    );
};

export default App;
