import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Context } from 'App';
import { Navigate, Outlet } from 'react-router';



const AuthenticationRouteWrapper = () => {
    const { signedIN } = useContext(Context);
    const loggedIN = localStorage.getItem('loggedIN')
    return signedIN ? <Navigate to='/dashboard/default' /> : <Outlet />
}

export default AuthenticationRouteWrapper